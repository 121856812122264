import React, {useContext, useEffect} from 'react'
import { useLocation } from 'react-router-dom'


//Animations
import {motion, AnimatePresence} from 'framer-motion'

//Image imports
import headerImg from '../../assets/gallery/Domestic/03 -Oak Porch/Full/1.jpg'

//Components
import Loading from '../loading/Loading'
import Card from '../../components/card/Card'
import ProjectFilter from './ProjectFilter'
import PageHero from '../../components/pageHero/PageHero'
import Footer from '../../components/footer/Footer'

//Context
import{ProjectsContext} from '../../context'

//SCSS
import './projects.scss'

const Projects = () => {


  let location = useLocation().pathname;


  const {loading, sortedProjects, projects, handleChange} = useContext(ProjectsContext);



  useEffect(() => {
    window.scrollTo(0,0);
 
  }, [])

  

  if(loading){
    return(
    <Loading />
    )
    }else{

    return (
        <motion.div
        initial={{opacity: 0}}
        animate={{opacity:1,
                 transition :{duration:1}   
                }}
        exit={{opacity:0}}
        
        
        >
       <PageHero title='Projects' img={headerImg} location={location}/>

       <div className="projectsWrapper">

               



            <ProjectFilter projects={projects} sortedProjects={sortedProjects} handleChange={handleChange}/>
            <AnimatePresence>
                <div className="projectsContainer">

                    {sortedProjects.map((project, i)=>(          
                        <Card project={project} i ={i} key={i}/>
                    ))}
                
                </div>
            </AnimatePresence>
        </div>
        
        <Footer />
        </motion.div>
       
    )
    }
}



export default Projects
