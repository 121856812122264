import React from "react";
import { NavLink } from "react-router-dom";

//Framer motion
import { motion} from "framer-motion";


//Components
import MenuToggle from "./MenuToggle";
import Navigation from "./Navigation";


//SCSS
import "./nav.scss";

//Logo
import logo from "./navLogo.png";

const NavBar = ({ toggle, isOpen }) => {



    return (
      <>
      <motion.div
      className="nav-wrapper">

        <motion.div 
      
        className="navBackground" />

        
      <nav>
          <div className="Logo">
            <NavLink exact to="/">
              <img src={logo} height="35px" alt='logo' />
            </NavLink>
          </div>
          <motion.div className="hamburger">
            <MenuToggle toggle={toggle} isOpen={isOpen} />
          </motion.div>
        </nav>

        <div>
          <motion.div initial={false}>
            <Navigation toggle={toggle} isOpen={isOpen} />
          </motion.div>
        </div>


      </motion.div>

      </>
    );
};

export default NavBar;
