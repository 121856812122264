import React, {useEffect, useState, useRef, createContext} from 'react';
import {useIntersection} from 'react-use';



export const IntersectionContext = createContext({inView: true});

export const IntersectionObserver = ({children,reset = true}) => {
    const [inView, setInView] = useState(false);
    const intersectionRef = useRef(null);
    const intersection = useIntersection(intersectionRef, {threshold: 0});

    useEffect(()=>{
        const inViewNow = intersection && intersection.intersectionRatio > 0;

        if(inViewNow){
            return setInView(inViewNow);
        }else if (reset){
            return setInView(false);
        }
    },[intersection, reset]);

    return (
        <IntersectionContext.Provider value={{inView}}>
            <div ref={intersectionRef}>{children}</div>
        </IntersectionContext.Provider>
    );
};