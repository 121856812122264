import React, {useContext} from "react";
import { NavLink } from "react-router-dom";

//Context
import{ProjectsContext} from '../../context'


//Framer Motion
import { motion } from "framer-motion";


const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const MenuItem = ({ id, title, path, toggle, isOpen}) => {


  const {handleChange} = useContext(ProjectsContext);


  const resetSortedProjects = (title) =>{

    if(title.title === "Projects"){

        handleChange([],[],[])
    }
    
  }



  return (
    <NavLink to={path}
          
          onClick={()=>{resetSortedProjects({title});}}
    >
      <motion.li
        key = {id}
        variants={variants}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={()=>{toggle();}}
        style={{ pointerEvents: isOpen ? "auto" : "none" }}
      >
        {title}
      </motion.li>
    </NavLink>
  );
};

export default MenuItem;
