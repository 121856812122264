import React from "react";
import ReactDOM from "react-dom";


import "./index.css";

import {BrowserRouter as Router} from 'react-router-dom'

import App from "./App";


import {HomeProvider, AboutPageProvider, WorkAddressProvider, ProjectProvider, ServicePageProvider, PageHeroProvider} from './context'

ReactDOM.render(
<React.StrictMode>
    
    <ProjectProvider>
    <AboutPageProvider>
    <ServicePageProvider>
    <HomeProvider>
    <WorkAddressProvider>
    <PageHeroProvider>
    
        
        <Router>
            <App />
        </Router>

    </PageHeroProvider>
    </WorkAddressProvider>
    </HomeProvider>
    </ServicePageProvider>
    </AboutPageProvider>
    </ProjectProvider>

</React.StrictMode>,
document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
 
