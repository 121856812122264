import React from 'react'

//Images

import logo from '../../components/nav/navLogo.png'

//SCSS
import './loading.scss'

function Loading() {
    return (
        <div className="loading">
            <div className="logo">
                <img src={logo} alt="DJ Read Logo" className="logo"/>
            </div>
            <div className="loadingText">
                <h4>Loading...</h4>
            </div>


        </div>
    )
}

export default Loading
