import React, {useState} from 'react'

//React Form
import emailjs from 'emailjs-com'
import {useForm} from 'react-hook-form'

//Animation
import {motion, AnimatePresence} from 'framer-motion'


//SCSS
import './contactSubmissionForm.scss'

const ContactForm = () => {

    const [formSubmitted, setFormSubmitted] = useState(false);



    const submitForm = data => {
   
        setFormSubmitted(true);

        emailjs.send('service_fwcid6a', 'template_novi1ma', data, 'user_9WTw1o5umlVPcHS7RMADl')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              setFormSubmitted(false);
              console.log(error.text);
          });

      }



      const {register, handleSubmit} = useForm();


    return (
        <>
        <AnimatePresence initial={false} exitBeforeEnter>

            {!formSubmitted && (
                    <motion.div 
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0 }}
                    
                    className='submissionFormWrapper'>
                    <form className='contact-submission-form' onSubmit={handleSubmit(submitForm)}>

                        <div className="contactFormGroup">
                            <label>Name:</label>
                            <input  type="text" {...register('name',{required: true})}  placeholder='Name' name="name" />
                        </div>
                    
                        <div className="contactFormGroup">
                            <label>Telephone:</label>
                            <input type="text" {...register('telephone',{required: true})} placeholder='Telephone' name="telephone" />
                        </div>
                        <div className="contactFormGroup">
                            <label>E-mail:</label>
                            <input type="email" {...register('email',{required: true})} placeholder='Email' name="email" />
                        </div>
                        <div className="contactFormGroup-message">
                                <label>Message:</label>
                                <textarea name="message"  {...register('message',{required: true})} placeholder='Message'/>
                        </div>
                        <div className="contactFormGroup">
                            <input type="submit" value="Submit" />
                        </div>

                    </form>
                    </motion.div>

            )  }
          
      </AnimatePresence>

      <AnimatePresence exitBeforeEnter>
                {formSubmitted && (
                      <motion.div
                      initial={{opacity: 1}}
                      animate={{opacity: 1}}
                      exit={{opacity: 0 }}

                      className='thankYouWrapper'
                      
                      >
          
                          <div>
                              <h2>Many thanks for your submission</h2>
                              <p>We will get back to you as soon as possible.</p>
                          </div>
          
                      </motion.div>       
                )}
                                    
      </AnimatePresence>
      </>


    )
}

export default ContactForm
