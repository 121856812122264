import React from "react";
import { motion, AnimateSharedLayout } from "framer-motion";
import "./dots.scss";

const colors = ["#cdcbcb", "#c30000"];

 const Dots = ({pageId, click, slides}) => {


    const dotColor =(dotId)=>{
        if (dotId === pageId){
            return(
                colors[1]
            )
        }
        else{
            return(
                colors[0]
            )
        }
    }

    const arrayLength = slides.length;

  return (
    <AnimateSharedLayout>
      <ul>

        {slides.map((dot,i) => {

            if(i < arrayLength){

              return(
                  <Item
                  key={dot.id}
                  color={dotColor(i)}
                  isSelected={pageId === i}
                  onClick={() => {click(i)}}
                />
                )}

                else{
                  return(null)
                }


            
        }
                 
        )}
      </ul>
    </AnimateSharedLayout>
  );
};

function Item({ color, isSelected, onClick }) {
    
    const spring = {
        type: "spring",
        stiffness: 500,
        damping: 30
        };

  return (
    <li className="item" onClick={onClick} style={{ backgroundColor: color }}>
      {isSelected && (
        <motion.div
          layoutId="outline"
          className="outline"
          initial={false}
          animate={{ borderColor: color }}
          transition={spring}
        />
      )}

    </li>
  );
}


export default Dots;


