import React, {useContext} from 'react'
import {Link} from 'react-router-dom'

import logo from '../../components/nav/navLogo.png'

import MenuItem from '../../components/nav/MenuItem'
import {menuLinks} from '../../components/nav/NavLinks'

import {WorkAddressContext} from '../../context'

import './footer.scss'

const Footer = () => {

    const {workAddress, footerLogos, loading} = useContext(WorkAddressContext);


    const workPhone = loading ? '' : workAddress[0].officeTelephone;
    const mobilePhone = loading ? '' : workAddress[0].mobileTelephone;

    const workPhoneNoSpace = workPhone.replace(/\s+/g, '');
    const mobilePhoneNoSpace =  mobilePhone.replace(/\s+/g, '');

    const certLogos = footerLogos;



    if(loading){
        return(
            <div></div>
        )
    }else{

            
    return (
        <footer>
            <div className="footer-container">
                <div className="footer-grid">
                    <div className="footer-logo-section">
                        <Link to='/'>
                        <img src={logo} alt='DJ Read Ltd'/>
                        </Link>
                    </div>
                    <div className="footer-nav-links">
                        <nav>
                            <ul>
                                {menuLinks.map((menuItem, i) => (
                                <MenuItem key={i} id={menuItem.id} title={menuItem.title} path={menuItem.path} />
                                ))}
                            </ul>
                        </nav>
                    </div>
                    <div className="footer-accreditations">
                       
                        {certLogos.map((item,i)=>(

                            <div key={i} className="acc-logo"><img src={item.url} alt="NICEIC"/></div>

                        ))}


                    </div>
                    <div className="footer-contact">
                        <ul>
                            <li><b>{workAddress[0].companyTitle}</b></li>
                            <li>{workAddress[0].address1}</li>
                            <li>{workAddress[0].address2}</li>
                            <li>{workAddress[0].address3}</li>
                            <li>{workAddress[0].address4}</li>
                            <li>{workAddress[0].postCode}</li>
                            <li> </li>
                            <li><a href={`tel:${workPhoneNoSpace}`}>{workPhone}</a></li>
                            <li><a href={`tel:${mobilePhoneNoSpace}`}>{mobilePhone}</a></li>
                            <li> </li>
                            <li><a href={`mailto:${workAddress[0].emailAddress}`}>{workAddress[0].emailAddress}</a></li>
                        </ul>
                                    
                    </div>
                </div>

                
            </div>
        </footer>
    )
     }
}

export default Footer
