import React from 'react'
import {motion} from 'framer-motion'

import './homeServices.scss'




const HomeServicesSlide = ({img,id,imgHeight, imgWidth}) => {



    return (
        <motion.div
        className='servicesImage'
        key={id}
       
        // style={{backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15) ), url(${img})`}}
        >
            <img src={img} alt={id}/>

        </motion.div>
    )
}

export default HomeServicesSlide
