import React, {useState} from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';



//Map Style


const mapStyle = {
  width: '50%',
  height: '100%'
}

const mapStyleTablet={
  width: '100%',
  height:'100%'
}


const GoogleMap = (props) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () =>{
    setWindowWidth(window.innerWidth)
  }

  window.addEventListener('resize', handleResize)


  return (
    <Map
    className ="googleMap"
    containerStyle={windowWidth <= 768 ? mapStyleTablet : mapStyle}
    google={props.google}
    zoom={11}
    initialCenter={{ lat: 51.87, lng: -2.45859}}
  >
      <Marker 
      icon={{
        url: 'https://api.iconify.design/mdi-map-marker.svg?height=24',
        anchor: new props.google.maps.Point(32,32),
        scaledSize: new props.google.maps.Size(64,64)
      }} 
      position={{ lat: 51.87, lng: -2.45859}} />

  </Map>
  )
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAc7ui-G08_3fE_rfvACtDGL-FtgYcpSs8',
  mapIds: '8cf85356386616fd'
})(GoogleMap);