import React, {useState} from 'react'


import {Link} from 'react-router-dom'

//Animations
import {motion, AnimateSharedLayout} from 'framer-motion'

//SCSS

import './card.scss'


const variants = {
    initial:{opactiy: 0},
    animate:{opacity:1},
    exit:{opacity:0}

}

const Card = ({project, i}) => {
    
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);

    return(


<Link to={`/projects/${project.slug}`} className="projectCard">

    <motion.div 
   variants={variants}
   initial='initial'
   animate='animate'
   exit='exit'
   custom={i}

    whileHover = {{
        scale: 1.01,
        transition:{duration:0.4}
                }}

    whileTap ={{scale: 0.99}}
    onMouseEnter={toggleOpen}
    onMouseLeave={toggleOpen}
    className="projectContainer" key={i}>
    
        <AnimateSharedLayout>

        <motion.div layout key={i} className="projectImage" style={{filter: isOpen ? 'brightness(0.5)' : 'brightness(1)' }}>
            <img src={project.thumbnail} alt="img1"/>
        </motion.div>
            <motion.div className="projectInfo" layout>

                <motion.ul layout>
                    <motion.li layout>
                    <h1>{project.title}</h1>
                    <div className="projectType">{project.projectType}</div>
                    </motion.li>
                    <motion.li layout>
                        {isOpen && <ContentButton />}
                    </motion.li>
                </motion.ul>
                
            </motion.div>
        </AnimateSharedLayout>


    </motion.div>
    </Link>


    )

}


const ContentButton = () =>{

    return(
         <motion.div
            layout
            initial={{ opacity: 0, y:10 }}
            animate={{ opacity: 1, y:0, transition:{duration: 0.5} }}
            exit={{ opacity: 0 }}
            
         >
             <button className="seeMoreBtn">
                 See More
             </button>
            

         </motion.div>

    )

}


export default Card
