import React, {useState,useEffect, useContext} from "react";

//Framer motion
import {motion,useViewportScroll, useTransform} from 'framer-motion'

//Components
import Loading from '../../pages/loading/Loading'


//Context

import{PageHeroContext} from '../../context'

//SCSS
import './pageHero.scss'


const PageHero = ({title, img, location}) => {

    const heroContext = useContext(PageHeroContext);

    const {loading, pageHeros} = heroContext;
    const [heroContent, setHeroContent] = useState();

    //Image and title states
    const [heroImage, setHeroImage] = useState();
    const [heroTitle, setHeroTitle] = useState();


    
    useEffect(() => {
        
          if (!loading){
            setHeroContent(pageHeros.filter(hero => hero.slug === location))    
          }
    }, [loading])


    useEffect(() => {

      if(typeof heroContent != "undefined"){

        setHeroImage(heroContent[0].heroImage)
        setHeroTitle(heroContent[0].title)

      }
       
     
    }, [heroContent])


  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0.1,0], [1.1,1])

  
    return (
      <section className="pageHeroContainer">
          <div className="pageHeroText"><h1>{heroTitle}</h1></div>
  
          <div className="pageHeroImage">
            <motion.img
            style={{scale}}
            
            
            src={heroImage} alt={heroTitle}/>
          </div>
      </section>
  
  
    )



}

  

export default PageHero
