import React from 'react'
import {Link} from 'react-router-dom'


//Framer motion
import {AnimatePresence, motion} from 'framer-motion'
import {framerTextItem} from '../../framerVariants/framerTextItem'
import {framerTextContainer} from '../../framerVariants/framerTextContainer'


//SCSS
import './slider.scss'




function Slide({img,title,id,subtitle,handleChange, slug}) {  

  


    return (
        
        <>
        <motion.div 
        key={id} 
        className='slide' 
        style={{backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15) ), url(${img})`}}

        



        >
        
        <AnimatePresence>
        <motion.div
        key='container'
        className="slide-text-container"
        variants={framerTextContainer}
        initial="hidden"
        animate="show"
        >
                <motion.div className="slide-title" key='title'
                variants={framerTextItem}
                >
                    <h1>{title}</h1>
                </motion.div>
                <motion.div className="slide-subtitle" key='subtitle'
                variants={framerTextItem}>
                    <h3>{subtitle}</h3>
                </motion.div>                                                                     
            <Link to={'/projects'} >
                <motion.div 
                    className="slide-link" 
                    key='link'
                    onClick={()=>{handleChange([],[],[slug])
                                }}

                    variants={framerTextItem}>
                    <h3>Click to view</h3>
                </motion.div>
            </Link>                                                                
            </motion.div>
            </AnimatePresence>

        </motion.div>
      
        
        
       
        </>
        
    )
    
}

export default Slide