import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

//Framer
import { AnimatePresence, motion, useCycle } from "framer-motion";


//SCSS + CSS
import "./App.scss";
import "animate.css/animate.min.css"

/* Pages */
import About from './pages/about/About'
import Contact from './pages/contact/Contact'
import Home from './pages/home/Home'
import Projects from './pages/projects/Projects'
import Project from "./pages/projects/Project";
import Services from './pages/services/Services'
import ServicePage from './pages/services/ServicePage'


//Components
import NavBar from "./components/nav/NavBar";
import Navigation from "./components/nav/Navigation";

function App() {
  const [isOpen, toggleOpen] = useCycle(false, true);

  const location = useLocation();
  
  useEffect(() => {
    let vh = window.innerHeight;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  return (
   
      <div className="App">
        <Navigation
          toggle={() => {
            toggleOpen();
          }}
          isOpen={isOpen}
        />
        <motion.div initial={false} animate={isOpen ? "open" : "closed"} >
          <NavBar
            toggle={() => {
              toggleOpen();
            }}
            isOpen={isOpen}
            

          />
        </motion.div>

        <div id="page-wrap" className='page-wrap'>
          <AnimatePresence  exitBeforeEnter>
            <Switch location={location} key={location.key}>

              <Route key='Home' path="/" exact component={Home} />
              <Route key='Contact' path="/contact" exact component={Contact} />
              <Route key='About' path="/about" exact component={About} />
              <Route key='Projects' path="/projects" exact component={Projects} />
              <Route key='Project' path ="/projects/:slug" exact component={Project} />
              <Route key='Services' path="/services" exact component={Services} />
              <Route key='ServicePage' path ="/services/:slug" exact component={ServicePage} />
            </Switch>

          </AnimatePresence>
        </div>
      </div>

  );
}

export default App;
