import React, {useContext} from 'react'
import {useLocation} from 'react-router-dom'


//Components
import ContactForm from './ContactForm';
import GoogleMap from './GoogleMap';
import Footer from '../../components/footer/Footer';

//Framer motion
import { motion } from 'framer-motion';


//SCSS
import './contact.scss'
import PageHero from '../../components/pageHero/PageHero';

const Contact = () => {
    window.scrollTo(0,0);
    let location = useLocation().pathname;


    return (
        <motion.div
        initial={{opacity: 0}}
        animate={{opacity:1,
                 transition :{duration:1}   
                }}
        exit={{opacity:0}}
        >
        <PageHero location={location} />
        <section>
        <div className="contact-wrapper">
            <div className="contact-details-wrapper">
                {/* <div className="contact-header">
                    <h1>Contact Us</h1>
                </div> */}

                <div className="contact-pre-text">
                    <p>
                        Please do not hesitate to get in touch and let us know what we can do to help.<br/>
                        Enter your details below and we will get back to you as soon as we can!

                    </p>
                </div>

                <div className="contact-form">
                    <ContactForm />
                </div>
              
        </div>

            <div className="contact-map">
                <GoogleMap />
            </div>

        </div>

        </section>
       

        <Footer />

        </motion.div>
       
    )
    }


export default Contact
