import React, {useContext} from 'react'
import {Link, useLocation} from 'react-router-dom'

//Animations
import {motion} from 'framer-motion'
// import {FadeInUpBox} from '../../components/framerFadeIn/FadeInUpBox'
// import {IntersectionObserver} from '../../components/framerFadeIn/IntersectionObserver'


//Framer motion variants
import {framerTextItem} from '../../components/framerVariants/framerTextItem'


//Components
import PageHero from '../../components/pageHero/PageHero'
import Loading from '../loading/Loading'
import Footer from '../../components/footer/Footer'

// //Contentful
// import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
// import {BLOCKS} from '@contentful/rich-text-types'

//Image Imports
import headerImg from '../../assets/gallery/Domestic/Octagon Extension/Full/01.jpg'

//Context
import{ServicePageContext} from '../../context.js'

//SCCSS
import './services.scss'

const Services = () => {
  window.scrollTo(0,0);
  let location = useLocation().pathname;

  const {loading, serviceContent} = useContext(ServicePageContext);
  
//   const RICHTEXT_OPTIONS ={
//     renderNode:{
//         [BLOCKS.PARAGRAPH]:(node, children) =>{
//             return <p>{children}</p>
//         },
//         renderText: text =>
//         text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
//     }

//     }


    if(loading){
        return(
            <Loading />

        )

    }else{

    // const serviceRichDescription = documentToReactComponents(servicePageText.description, RICHTEXT_OPTIONS);


        return (
            <motion.div
            initial={{opacity: 0}}
            animate={{opacity:1,
                     transition :{duration:1}   
                    }}
            exit={{opacity:0}}
            >
                <PageHero title='Services' img={headerImg} location={location}/>             
                <div className="servicesPageWrapper">
                           
                
                        {serviceContent.map((service,i)=>(

                    <div key={i} className="servicesSection">

                            <div className="serviceSectionWrapper">
                                   
                                    <div className="serviceSectionImage">
                                        <img src={service.servicesSectionImage} alt=""/>
                                    </div>

                                    <div className="serviceSectionText">
                                        <div className="serviceSectionTitle">
                                            <h2>{service.servicesSectionTitle}</h2>
                                        </div>
                                        <div className="serviceSectionDescription">
                                          <p>{service.servicesSectionDescription}</p> 
                                        </div>
                                        
                                       <LearnMoreButton slug={service.slug} />

                                    </div>

                            </div>
                    </div>
                     

                            
                        ))}                                 
                        
                        

                    </div>
            <Footer />
            </motion.div>
           
        )

    }

}

export default Services


const LearnMoreButton = ({slug}) =>{

    return(
        <Link to={`/services/${slug}`} className="serviceLinkBtnWrap">
                                        
        <motion.button 

            whileHover = {{
            scale: 1.02,
            transition:{duration:0.4}
                    }}
            
                    whileTap ={{scale: 0.99}}

            variants={framerTextItem}
            className="learnMoreBtn">
                    Learn More
        </motion.button>                
    </Link>


    )

  




}