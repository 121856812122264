import React from 'react'


//Animations
import {motion} from 'framer-motion'
import {FadeInUpBox} from '../../../components/framerFadeIn/FadeInUpBox'
import {IntersectionObserver} from '../../../components/framerFadeIn/IntersectionObserver'


//SCSS
import './homeAboutSection.scss'

const HomeAboutSection = ({title, description, image}) => {
    return (
        <section className='homeAboutSection'>
            

            <motion.div className = 'leftBlock'>
                <IntersectionObserver>
                    <FadeInUpBox>

                        <motion.div className="homeAboutCentreText">
                            <h1>{title}</h1>
                            <p>{description}</p>
                        </motion.div>
                    </FadeInUpBox>
                </IntersectionObserver>
            </motion.div>
            <div className="rightBlock">
            <IntersectionObserver>

                <FadeInUpBox>
                    <motion.div className="homeAboutImage">
                        <img src={image} alt={title}/>
                    </motion.div>
                </FadeInUpBox>
                </IntersectionObserver>
            </div>
            
        </section>
    )
}

export default HomeAboutSection
