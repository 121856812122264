import React from 'react'
import Card from '../../card/Card'

import './selectedProjects.scss'

const SelectedProjects = ({featuredProjects}) => {
    return (
        
        <section className="featuredProjectsContainer">

            <div className="featuredProjectsTitle">
                <h1>Featured Projects</h1>
            </div>

            <div className="cardWrapper">
                {featuredProjects.map((project, i)=>(
                
                        <Card key={i} project={project} i ={i} />

                ))}
            </div>
            
           
              
         
        </section>
    )
}

export default SelectedProjects
