import React, { useState, useEffect, useContext }  from "react";

//Left and right slide arrow
import { Icon } from '@iconify/react';
import circleChevronLeft from '@iconify-icons/akar-icons/circle-chevron-left';
import circleChevronRight from '@iconify-icons/akar-icons/circle-chevron-right';

//Framer motion
import { motion, AnimatePresence } from "framer-motion";


//Context
import {ProjectsContext} from '../../../context'


//SCSS
import './slider.scss'

//Components
import Slide from './Slide'
import Dots from './Dots'
import { wrap } from "popmotion";


const variants = {
  enter: (direction) => {
    return {
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      opacity: 0
    };
  }
};




const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};







const Slider = ({slides}) => {


  const context = useContext(ProjectsContext);
  const {handleChange, loading} = context;

  const [page, setPage] = useState(0);
 
  const slideIndex = wrap(0, slides.length, page);

  const resetPage = (dot) => {
      setPage(dot);
      }
      

  const paginate = (newDirection) => {

    if(slideIndex !== slides.length){
       setPage(slideIndex + newDirection);
    }


  }


useEffect(()=>{

      const timer = setInterval( ()=>{
          
          if(slideIndex !== slides.length){
            setPage(slideIndex + 1);
         }

    },6000);
    return()=>{
      clearInterval(timer)
    }

},[page, slideIndex, slides.length])




if(!loading){

 
  return (
    <>
    
    <section className='slider-section'>
      
        <AnimatePresence initial={false} exitBeforeEnter>
            <motion.div
            className='slide-wrapper'
            key={slideIndex}
            variants={variants}
            initial='enter'
            animate='center'
            exit='exit'

            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
            }
            }}

            >
                <Slide 
                key={slideIndex}
                id={slideIndex} 
                img={slides[slideIndex].sliderImage}  
                title={slides[slideIndex].title} 
                subtitle = {slides[slideIndex].subtitle}
                handleChange={handleChange}
                slug={slides[slideIndex].slug}
                />

                  <AnimatePresence>
                  <motion.div 
                  initial={{opacity: 0}}
                  whileHover={{opacity: 1}}
                  key='leftChev'

                  onClick={()=>{paginate(-1)}}

                  className="leftButtonContainer">
                    <motion.div
                    whileTap={{scale:0.8}}
                    
                    >
                      <Icon icon={circleChevronLeft} />
                    </motion.div>
                  </motion.div>

                  <motion.div 
                  initial={{opacity: 0}}
                  whileHover={{opacity: 1}}
                  key='rightChev'

                  onClick={()=>{paginate(1)}}

                  className="rightButtonContainer">

                    <motion.div
                    whileTap={{scale:0.8}}
                    >
                      <Icon icon={circleChevronRight} />
                    </motion.div>
                  </motion.div>

                  </AnimatePresence>
            </motion.div>
          </AnimatePresence>       

          <div className="dots-container">
            <Dots pageId={slideIndex} click={resetPage} slides={slides}/>
          </div>
          
      </section>
    </>
  );

  }


};

export default Slider