import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import MenuItem from "./MenuItem";

import {menuLinks} from './NavLinks'


const variants = {
  open: {
    transition: {
      staggerChildren: 0.08,
      delayChildren: 0.2,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.08,
      staggerDirection: 0.2,
    },
  },
};

const menu = {
  open: { opacity: 1, y: 0, display: "flex" },
  closed: { opacity: 0, y: 1, transitionEnd: { display: "none" } },
};

const Navigation = ({ toggle, isOpen }) => {


  return (
    <AnimatePresence initial={false}>
    <motion.div
      className="menuBackground"
  
      animate={isOpen ? "open" : "closed"}
      

      variants={menu}
    >
      <motion.ul
        style={{ pointerEvents: isOpen ? "auto" : "none" }}
        variants={variants}
      >
        {menuLinks.map((menuItem, i) => (
          <MenuItem key={i} id={menuItem.id} title={menuItem.title} path={menuItem.path} toggle={toggle} isOpen={isOpen} />
        ))}
      </motion.ul>
    </motion.div>
    </AnimatePresence>
  );
};

export default Navigation;
