import React, {useContext, useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'

//Contentful Rich Text
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {BLOCKS} from '@contentful/rich-text-types'



//Animations
import {AnimatePresence, motion} from 'framer-motion'
import {FadeInUpBox} from '../../components/framerFadeIn/FadeInUpBox'
import {IntersectionObserver} from '../../components/framerFadeIn/IntersectionObserver'


//Components
import Footer from '../../components/footer/Footer'
import Loading from '../loading/Loading'


//SCSS
import './project.scss'

//FramerMotion variants
import {framerTextContainer} from '../../components/framerVariants/framerTextContainer'
import {framerTextItem} from '../../components/framerVariants/framerTextItem'

//Context
import{ProjectsContext} from '../../context'




const Project = () => {

    
    //Ensure page starts from the top
    window.scrollTo(0,0);


    let {slug} = useParams();

    const {getProject, loading} = useContext(ProjectsContext);

    let projectFound = false;

    const project = getProject(slug);

    if(!project){
        projectFound = false
    }else{
        projectFound = true
    }

        
        const projectTitle = projectFound ? project.title : '';
        const projectImages = projectFound ? project.images : null;

   

    const RICHTEXT_OPTIONS ={
        renderNode:{
            [BLOCKS.PARAGRAPH]:(node, children) =>{
                return <p>{children}</p>
            },
            renderText: text =>
            text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
        }

    }
    const projectRichDescription = projectFound ? documentToReactComponents(project.description, RICHTEXT_OPTIONS) : null;




    //Check that images have loaded before displaying page
 
    const [imagesLoaded, setImagesLoaded] = useState(0);
    const [boImagesLoading, setBoImagesLoading] = useState(true);
    const totalNumberOfImages = projectFound ? projectImages.length : null;

    const imageCounter = () =>{
        setImagesLoaded(imagesLoaded + 1)
    }

    useEffect(() => {
    
        if (imagesLoaded === totalNumberOfImages){
            setBoImagesLoading(false)
        }
     
    }, [imagesLoaded])

    
    //Check that API and images have loaded and then display content

    if(loading){
        return(
            <Loading />
        )
    }else{

        return (

            // Page animation
            <motion.div
            initial={{opacity: 0}}
            animate={{opacity:1,
                     transition :{duration:1}   
                    }}
            exit={{opacity:0,
                transition:{duration: 1}
            }}
            >

            {/* Loading screen animation */}
            <AnimatePresence>
            <motion.div 
            initial={{opacity: 1}}
            animate={{opacity:1,
                     transition :{duration:1}   
                    }}
            exit={{opacity:0,
                transition:{duration: 1}
            }}
            
            
            className='loadingCover' style={{display: boImagesLoading ? "block":"none"}}>
            <Loading />

            </motion.div>
            </AnimatePresence>   
        
            <section className="projectWrapper">
            <div className="page-wrapper">
        
        
                <motion.div 
                 key='container'
                 className="project-hero" 
                 variants={framerTextContainer}
                 initial="hidden"
                 animate="show">
        
                    <motion.div className="title-container">
                        
                        <motion.div className="header"
                            variants={framerTextItem}
                            
                            ><h1>{projectTitle}</h1> </motion.div>
                            <motion.div 
                            variants={framerTextItem}
                            className="description">
                                    {projectRichDescription}

                            <Link to ='/projects' >
                                <motion.div 

                                whileHover = {{
                                scale: 1.02,
                                transition:{duration:0.4}
                                        }}
                                
                                        whileTap ={{scale: 0.99}}

                                variants={framerTextItem}
                                className="projectBackButton">
                                        <p>Back to Projects</p>
                                </motion.div>       
                            </Link>

                            </motion.div>

                           

                        </motion.div>
        
                    </motion.div>
        
                    <div className="projectImages">
                    
                        {projectImages.map((image,i)=>(
                            <IntersectionObserver key={i}>
                                <FadeInUpBox>
                                <div  className="image" key={i} >
                                    <img onLoad={imageCounter} src={image.fields.file.url} alt={image.fields.file.title}/>
                                </div>
                                </FadeInUpBox>
                            </IntersectionObserver>
                        ))}
                
                    </div>
                    
            </div>
        
            <Footer />
                
            </section>
        
            </motion.div>
            )
    }
    

   
}

export default Project
