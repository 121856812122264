import React, {useState, useEffect} from 'react'


//Components
import MenuToggle from '../../components/nav/MenuToggle'

//Framer motion
import {motion, AnimatePresence, useCycle} from 'framer-motion'

//SCSS
import './projectFilter.scss'


//get all unique values
const getUnique = (items, value) =>{
    return [...new Set(items.map(item => item[value]))]
}


const ProjectFilter = ({projects, sortedProjects, handleChange}) => {


    let finalProjects = [];

    projects.forEach(pr1 => sortedProjects.forEach(pr2 => {

        if(pr1 === pr2){
            finalProjects.push(pr1)
        }

    }))



    let sortedWorkType = [];
    let sortedServiceType = [];
    let sortedProjectType = [];

    sortedProjects.forEach(project =>{

        let workTypeExists = sortedWorkType.includes(project.workType);

        if(!workTypeExists){
            sortedWorkType.push(project.workType)
        }
    })

    sortedProjects.forEach(project =>{

        let serviceTypeExists = sortedServiceType.includes(project.service);

        if(!serviceTypeExists){
            sortedServiceType.push(project.service)
        }
    })

    sortedProjects.forEach(project =>{

        let projectTypeExists = sortedProjectType.includes(project.projectType);

        if(!projectTypeExists){
            sortedProjectType.push(project.projectType)
        }
    })


        
        //Checkbox lists

        let workTypeChk = getUnique(projects,'workType')
        let servicesChk = getUnique(sortedProjects,'service')
        let projectsChk = getUnique(sortedProjects,'projectType')

        const [workTypeState, setWorkTypeState] = useState(sortedWorkType);
        const [serviceState, setServiceState] = useState(sortedServiceType);
        const [projectState, setProjectState] = useState(sortedProjectType);

        const [initialRender, setInitialRender] = useState(0)

        



        const updateFilter = () =>{ 


            //Work Type

            let workTypeArr = document.getElementsByName('workType');
            workTypeArr = [].slice.call(workTypeArr, 0);

            let workTypeFiltered = [];

            if(workTypeArr.length > 0){

                workTypeArr.forEach(item => {
                    if(item.checked === true){
                        workTypeFiltered.push(item.id)
                     }
                })

                   
            }

            //Service Type
            
            let serviceTypeArr = document.getElementsByName('serviceType');
            serviceTypeArr = [].slice.call(serviceTypeArr, 0);

            let serviceFiltered = [];

            if(serviceTypeArr.length > 0){

                serviceTypeArr.forEach(item =>{
                    if(item.checked === true){
                        serviceFiltered.push(item.id)
                    }
                })
            }

            //Service Type

            let projectTypeArr = document.getElementsByName('projectType');
            projectTypeArr = [].slice.call(projectTypeArr, 0);

            let projectFiltered = [];

            if(projectTypeArr.length > 0){

                projectTypeArr.forEach(item =>{
                    if(item.checked === true){
                        projectFiltered.push(item.id)
                    }

                })
            }

            setWorkTypeState(workTypeFiltered)
            setServiceState(serviceFiltered)
            setProjectState(projectFiltered)
         
        }



        useEffect(() => {
            
            if(initialRender !== 0){

                handleChange(workTypeState, serviceState, projectState);
            }

            setInitialRender(initialRender + 1)

        }, [workTypeState,serviceState, projectState])






              const framerItem = {
                initial: (i)=>({ opacity: 0, y: i * 20 }),
                animate: (i) =>({ opacity: 1, y:0, 
                    transition:{
                        delay: i * 0.05,
        

                    }
                }),
                exit: (i)=>({
                    opacity: 0,
                    y: i*20,
                    transition:{
                        delay: i * 0.05,
                    }
                })
        }

        const itemChecked = (item, type) =>{

            if(sortedProjects.length === projects.length){
                return false;
            }else{

                if(type === 'workType'){
                    return workTypeState.includes(item);
                }else{
                    if(type === 'serviceType'){
                        
                        return serviceState.includes(item)
                    }else{
                        if(type === 'projectType'){

                         return projectState.includes(item);
                        }
                    }
            }
            
            }
        }

        //Collapsible filter menu

        const [isOpen, setMenuOpen] = useCycle(false, true);

 

    return (
        <motion.div className='filterContainer'>
            <motion.div 
            initial={false}
            animate={isOpen ? "open" : "closed"}          
            
            className="filterHeader">
                <h2>Filter Projects</h2>
                <MenuToggle toggle={()=>{setMenuOpen()}} isOpen={isOpen}/>

            </motion.div>

        <AnimatePresence>
            <motion.div 
            layout
                      
            className="formContainer">

                {isOpen &&
                

                <form action="" className="filterForm">
                    <div className="formGroup">
                        <h3>Work Type</h3>

                        <AnimatePresence>
                            {workTypeChk.map((item,i)=>(
                                <motion.div 
                                key={i}
                                variants={framerItem}
                                initial='initial'
                                animate='animate'
                                exit='exit'
                                custom={i}
                                layout
                                className="checkboxContainer"
                                >
                                    <motion.input
                                    layout
                                    type="checkbox" 
                                    name='workType' 
                                    id={item} 
                                    checked={itemChecked(item, 'workType')}

                                    onChange={()=>{updateFilter()}}
                                    />
                                    <label htmlFor="WorkType">{item}</label>

                            </motion.div>                   
                            ))}

                    </AnimatePresence>
                    </div>


                    <div className="formGroup">
                        <h3>Service</h3>

                            <motion.div 
                            key='serviceChkBoxes'
                            className="checkboxWrapper">
                                
                                <AnimatePresence>

                                {servicesChk.map((item,i)=>(
                                    <motion.div 
                                    key={i}
                                    variants={framerItem}
                                    initial='initial'
                                    animate='animate'
                                    exit='exit'
                                    custom={i}
                                    layout
                                    className="checkboxContainer"
                                    >
                                        <motion.input
                                        layout
                                        type="checkbox" 
                                        name='serviceType' 
                                        id={item} 
                                        checked={itemChecked(item, 'serviceType')}
                                        onChange={()=>{updateFilter()}}/>                            
                                        <motion.label
                                        layout
                                        
                                        htmlFor="ServiceType">{item}</motion.label>
                                </motion.div>
                                    
                                
                                ))}
                        </AnimatePresence>

                            </motion.div>
                    </div>

                    <div className="formGroup">
                        <h3>Project Type</h3>
                        
                        <motion.div 
                        key='projectsChkBoxes'
                        className='checkboxWrapper'>

                            <AnimatePresence>

                            {projectsChk.map((item,i)=>(
                                    <motion.div
                                    key={i}
                                    variants={framerItem}
                                    initial='initial'
                                    animate='animate'
                                    exit='exit'
                                    custom={i}
                                    layout
                                    className="checkboxContainer"     
                                    >
                                        <motion.input 
                                        type="checkbox" 
                                        name='projectType' 
                                        id={item}
                                        layout
                                        checked={itemChecked(item, 'projectType')}
                                        onChange={()=>{updateFilter()}}/>
                                        <motion.label 
                                        layout
                                        htmlFor="projectType">{item}</motion.label>
                                    </motion.div>
                            ))}
                            </AnimatePresence>                

                        </motion.div>
                    
                    </div>
                
                    </form>

                }
                </motion.div>
            </AnimatePresence>
            
        </motion.div>
    )
}

export default ProjectFilter
