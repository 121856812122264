import React, { useContext} from 'react'


/*Sections*/
import HeroSlide from '../../components/homePage/heroSlider/Slider'
import HomeAboutSection from '../../components/homePage/homeAboutSection/HomeAboutSection'
import HomeServices from '../../components/homePage/homeServices/HomeServices'
import SelectedProjectSection from '../../components/homePage/selectedProjects/SelectedProjects'
import Footer from '../../components/footer/Footer'


//Framer motion
import {motion} from 'framer-motion'

//Component
import Loading from '../../pages/loading/Loading'

//Context
import {HomeContext, ProjectsContext} from '../../context'

//SCSS
import './home.scss'




const Home = () => {

    const {slideLoading, slides, sectionContent, sectionLoading} = useContext(HomeContext);
    const {loading, projects} = useContext(ProjectsContext);

    const featuredProjects = loading ? null :projects.filter(project => project.featuredProject === true);

    const sectionTitle = sectionContent.title
    const sectionDescription = sectionContent.description
    const sectionImage = sectionContent.image
    
    window.scroll({top:0, left:0, behavior:'smooth'})


    if(slideLoading || loading || sectionLoading){
        return(
            <>
            <Loading />
            </>
        )
    }else{
        
        return (
            <motion.div
            initial={{opacity: 0}}
            animate={{opacity:1,
                     transition :{duration:1}   
                    }}
            exit={{opacity:0}}
            
            >
        
            <HeroSlide slides = {slides} />
            <HomeAboutSection title={sectionTitle} description={sectionDescription} image={sectionImage} />
            <HomeServices />
            <SelectedProjectSection featuredProjects={featuredProjects}  />
            <Footer />
        
            </motion.div>

        )
}};


export default Home
