export const framerTextItem = {
    hidden: { 
        opacity: 0
        },
    show: { 
        opacity: 1
    
    }
}

