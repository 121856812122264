import React, {useState} from 'react'
import {AnimatePresence, motion} from 'framer-motion'
import {Link} from 'react-router-dom'

import './homeServices.scss'

import Electrical from '../../../assets/gallery/Domestic/Electrical Works/Full/StaircaseLighting.JPG'
import Construction from '../../../assets/gallery/Domestic/01 -A Wyman Extension/Full/16.JPG'
import Landscaping from '../../../assets/gallery/Domestic/Landscaping and Patio Project/Full/01.jpg'


import HomeServicesSlide from './HomeServicesSlide'


const HomeServices = () => {

    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;


        const variants = {
            initial:{
                opacity: 0,
                x:0, y:0
            },
            animate: {
                opacity: 1,x:0, y:0,
                transition:{
                    duration: 1.5,
                    easing: 'easeInOut'
                }
            },
            exit:{
                opacity: 0.2,x:0, y:0,
                transition:{
                    duration: 0.5,
                    easing: 'easeInOut'
                }
            }


        }

        const [hoveredBG, setHoveredBG] = useState(0);

        const services = [
            {title:'Electrical', img: Electrical, link: 'services/electrical'}, 
            {title:'Construction', img: Construction, link: 'services/construction'},
            {title:'Landcaping', img: Landscaping, link: 'services/landscaping'}
            ]

    return (
        <section className='homeServicesSection'>

          
    <motion.div className="homeServicesWrapper">

          

                <div className="buttonswrapper">

                    {services.map((service,i)=>(

                            <Link key={i} to={service.link} className='serviceLink'>
                            <motion.div
                            key={i}
                            onMouseEnter={()=>{setHoveredBG(i)}}
                            className="service">
                            <h2>{service.title}</h2>
                                
                                <motion.div
                                key={i}
                                variants={variants}
                                initial='initial'
                                animate={{ opacity: hoveredBG === i ? 1 : 0, y: hoveredBG === i ? 0 : 25  }}
                                
                                >
                                <p>Click to view</p>

                                </motion.div>
                               

                            </motion.div>
                            </Link>

                    ))}

                </div>
                <AnimatePresence >
                <motion.div 
                 key={services[hoveredBG].title}
                 variants={variants}
                 initial='initial'
                 animate='animate'
                 exit='exit'
                className="homeSlideWrapper">
                        <HomeServicesSlide key={hoveredBG} img={services[hoveredBG].img} 
                        id={services[hoveredBG].title} 
                        imgHeight={viewportHeight}
                        imgWidth={viewportWidth}/>

                </motion.div>

            </AnimatePresence>




                </motion.div>

        </section>
      
    )
}

export default HomeServices
