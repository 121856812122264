import React, {useContext, useEffect} from 'react'
import {useParams, useLocation} from 'react-router-dom'


//Contentful Rich Text
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {BLOCKS} from '@contentful/rich-text-types'

//Components
import PageHero from '../../components/pageHero/PageHero'
import Loading from '../loading/Loading'
import Card from '../../components/card/Card'

import Footer from '../../components/footer/Footer'

//SCSS
import './servicePage.scss'

//Framer motion
import { motion } from 'framer-motion'
import {FadeInUpBox} from '../../components/framerFadeIn/FadeInUpBox'
import {IntersectionObserver} from '../../components/framerFadeIn/IntersectionObserver'


//Context
import{ServicePageContext} from '../../context.js'
import{ProjectsContext} from '../../context'



const ServicePage = () => {

    window.scroll(0,0);
    let location = useLocation().pathname;

    const context = useContext(ProjectsContext);
    const {serviceHandleChange, sortedProjects, loading} = context;

     let {slug} = useParams();
 
     const {getService} = useContext(ServicePageContext);
 
     let serviceFound = false;
 
     const service = getService(slug);
 
     if(!service){
        serviceFound = false
     }else{
        serviceFound = true;
     }
     


        
     const formatImages = (items) =>{
        let tempItems = items.map(item=>{
            let url = item.fields.file.url

            return url
        })

        return tempItems


        }
 
        useEffect(() => {

            if(serviceFound){
                serviceHandleChange(service.projectFilterCategory) ;

            }

        }, [serviceFound])

      


        const serviceTitle = serviceFound ? service.servicesSectionTitle : '';
        const serviceHeaderTxt = serviceFound ? service.servicePageHeaderTextFirst : '';

        const serviceHeaderImg = serviceFound ? service.servicesSectionImage : '';

        const serviceImages = serviceFound ?  formatImages(service.servicePageImages) : null;


        const serviceImage01 = serviceImages ? serviceImages[0] : null;
        const serviceImage02 = serviceImages ? serviceImages[1] : null;


     

        const RICHTEXT_OPTIONS ={
            renderNode:{
                [BLOCKS.PARAGRAPH]:(node, children) =>{
                    return <p>{children}</p>
                },
                renderText: text =>
                text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
                [BLOCKS.UL_LIST]:(node, children) =>{
                    return <ul>{children}</ul>
                },
                [BLOCKS.LIST_ITEM]:(node, children) =>{
                    return <li>{children}</li>
                }
            }
    
        }


        const serviceBodyTxt = serviceFound ? documentToReactComponents(service.servicePageBodyRichText, RICHTEXT_OPTIONS) : null;
      

    if(loading){
        return(
            <Loading />
        )
    }else{
        return (
            <motion.div
            initial={{opacity: 0}}
            animate={{opacity:1,
                     transition :{duration:1}   
                    }}
            exit={{opacity:0}}
            >
                <PageHero title={serviceTitle} img={serviceHeaderImg} location={location} />

                <div className="servicePageWrapper">
                    <div className="serviceWrapper">

                        <IntersectionObserver>
                            <FadeInUpBox>
                                <div className="displayImageRight">
                                    <div className="startImg">
                                        <img src={serviceImage01} alt=""/>
                                    </div>

                                    <div className="headerTxt">
                                        <p>{serviceHeaderTxt}</p>
                                    </div>
                                </div>
                                
                            </FadeInUpBox>
                        </IntersectionObserver>

                        <IntersectionObserver>
                        
                            <FadeInUpBox>
                                <div className="displayImageLeft">
                                    <div className="img02">
                                        <img src={serviceImage02} alt=""/>
                                    </div>

                                    <div className="serviceBodyTxt">
                                        {serviceBodyTxt}
                                    </div>
                                </div>
                            
                            </FadeInUpBox>
                        </IntersectionObserver>
                    
                    
                    </div>
                    <section className="relatedProjects">

                        <div className="relatedProjectsHeader">
                            <h2>Related Projects</h2>
                        </div>

                        <div className="relatedProjectContainer">
                            {sortedProjects.map((project, i)=>(
                                <Card project={project} i ={i}  key={i} />
                            ))}


                        </div>

                    </section>
                </div>

                <Footer />



            </motion.div>
        )


    }


 
}

export default ServicePage
