export const framerTextContainer = {
    hidden: { 
        opacity: 0,
        scaleY:0
        },
    show: {
      opacity: 1,
      scaleY:1,
      transition: {
        duration: 0.8,
        staggerChildren: 0.5
      }
    }
  }