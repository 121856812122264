import React, { useContext } from "react";
import {useLocation} from 'react-router-dom'

//Images
import headerImg from '../../assets/gallery/Domestic/02 - Dave Nutter - May Hill/Full/25.jpg'

//Animations
import {motion} from 'framer-motion'
import {FadeInUpBox} from '../../components/framerFadeIn/FadeInUpBox'
import {IntersectionObserver} from '../../components/framerFadeIn/IntersectionObserver'

//Context
import{AboutPageContext} from '../../context'

//SCSS
import "./about.scss";

//Components
import PageHero from '../../components/pageHero/PageHero'
import Loading from '../../pages/loading/Loading'
import Footer from '../../components/footer/Footer'

function About() {
  window.scrollTo(0,0);
  let location = useLocation().pathname;

  const {loading, aboutSections} = useContext(AboutPageContext);
  
  if(loading){
    return(
    <Loading />
    )
    }else{
      
  return (
    <motion.div
    initial={{opacity: 0}}
    animate={{opacity:1,
             transition :{duration:1}   
            }}
    exit={{opacity:0}}
    >
      {/* <header> */}

        <PageHero title='About Us' img={headerImg} location={location}/>
      

        {aboutSections.map((about,i)=>(
          <div key={i} className="aboutSection">

                  <section className="aboutSectionWrapper">
                  <IntersectionObserver>
                    <FadeInUpBox>
                      <div className="aboutImage"><img src={about.image} alt={about.imageAlt}/></div>
                      <div className="aboutText">
                        <h2>{about.title}</h2>
                        <p>{about.description}</p>
                      </div>
                    </FadeInUpBox>
                    </IntersectionObserver>
                  </section>
            </div>

              
        ))}

  <Footer />
  </motion.div>
  )}};

export default About;
