import React, {useEffect, createContext, useState} from 'react';
import Client from './Contentful';



//Slider context

export const HomeContext = createContext();

export const HomeProvider = (props) =>{

    const [slideLoading, setSlideLoading] = useState(true);
    const[slides, setSlides] = useState();

    const [sectionLoading, setSectionLoading] = useState(true);
    const [sectionContent, setSectionContent] = useState(0);


    const getData = async()=>{
        try{
            let response = await Client.getEntries({
                content_type: "sliderImages",
                order: "fields.title"
            });
            
        setSlides(formatData(response.items));
        setSlideLoading(false);

        }
        catch(error){
            console.log(error)
        }
    }

    const getSectionData = async()=>{
        try{
            let response = await Client.getEntries({
                content_type: "homePageContent"
            })
            setSectionContent(formatSectionContent(response));
            setSectionLoading(false);

        }
        catch(error){
            console.log(error)
        }


    }

    useEffect(()=>{
        getData();
        getSectionData();
    },[])



    const formatData = (items) =>{
        let tempItems = items.map(item => {
            let id = item.sys.id
            let image = item.fields.sliderImage.fields.file.url
            let room ={...item.fields, sliderImage: image, id};
            return room;
        })
        return tempItems;
    }

    const formatSectionContent = (items) =>{

            let title = items.items[0].fields.title
            let description = items.items[0].fields.description
            let image = items.items[0].fields.image.fields.file.url

            let section = {title, description, image};

            return section;
        }

  
return(
    
    <HomeContext.Provider value={{ slideLoading, slides, sectionLoading, sectionContent }}>
        {props.children}
    </HomeContext.Provider>
)
};

//About page content context

export const AboutPageContext = createContext();

export const AboutPageProvider = (props) =>{

    const [loading, setLoading] = useState(true);
    const [aboutSections, setAboutSections] = useState(0);

  //getData
  const getData = async()=>{
    try{
        let response = await Client.getEntries({
            content_type: "aboutPageContent",
            order: "fields.orderId"
        });
            
        setAboutSections(formatData(response.items));
        
        setLoading(false);

    }
    catch(error){
        console.log(error)
    }
}



useEffect(()=>{
    getData();
},[])

const formatData = (items) =>{
    let tempItems = items.map(item => {
        let id = item.sys.id
        let image = item.fields.image.fields.file.url
        let imageAlt = item.fields.image.fields.title
        let aboutSection ={...item.fields, id, image, imageAlt};

        return aboutSection;
    })
    return tempItems;

}

    return(

    <AboutPageContext.Provider value={{loading, aboutSections}}>
        {props.children}
    </AboutPageContext.Provider>

    )

}


//Service page content context

export const ServicePageContext = createContext();

export const ServicePageProvider = (props) =>{

    const [loading, setLoading] = useState(true);
    const [servicePageText, setServicePageText] = useState(0);
    const [serviceContent, setServiceContent] = useState(0);



       //getData
       const getData = async()=>{
        
        try{
       
            let serviceResponse = await Client.getEntries({
                content_type: "servicePageDescription"
            })
    
            let serviceContentResponse = await Client.getEntries({
                content_type: "servicePageContent"
    
            })
    
    
            setServicePageText(serviceResponse.items[0].fields);
            setServiceContent(formatData(serviceContentResponse.items));
            setLoading(false);
    
        }
        catch(error){
            console.log(error)
        }
    }

                                    
 


    const getService = (slug) =>{

        if(!loading){
            let tempService = [...serviceContent]
            const service = tempService.find(service => service.slug === slug);

            return service;
        }
    }


const formatData = (items) =>{
    let tempItems = items.map(item => {
        // let id = item.sys.id
        let servicesSectionImage = item.fields.servicesSectionImage.fields.file.url
        let serviceSection ={...item.fields, servicesSectionImage};

        return serviceSection;
    })
    return tempItems;

}

useEffect(()=>{
    getData();
},[])

    return(

    <ServicePageContext.Provider value={{loading, servicePageText, serviceContent, getService: getService}}>
        {props.children}
    </ServicePageContext.Provider>

    )

}

//Page Heros

export const PageHeroContext = createContext();

export const PageHeroProvider = (props) =>{

    const [loading, setLoading] = useState(true);
    const [pageHeros, setPageHeros] = useState(0);

    //getData
    const getData = async()=>{

        try{
            let response = await Client.getEntries({
                content_type: "pageHeroContent"
            });
                
            setPageHeros(formatData(response.items));

            setLoading(false);

        }
        catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        getData();
    },[])

    const formatData = (items) =>{
        let tempItems = items.map(item => {
            let id = item.sys.id
            let heroImage = item.fields.heroImage.fields.file.url;
            let herosArray ={...item.fields, id, heroImage};
    
            return herosArray;
        })
        return tempItems;
    }

    return(

        <PageHeroContext.Provider value={{loading, pageHeros}}>
            {props.children}
        </PageHeroContext.Provider>
    
        )


}






//Work Address

export const WorkAddressContext = createContext();


export const WorkAddressProvider = (props) =>{

    const [loading, setLoading] = useState(true);
    const [workAddress, setWorkAddress] = useState(0);

    const [footerLogos, setFooterLogos] = useState(0);


      //getData
      const getData = async()=>{
        try{
            let response = await Client.getEntries({
                content_type: "businessAddress"
            });
                
            setWorkAddress(formatData(response.items));
    
            let logoReponse = await Client.getEntries({
                content_type: "footerLogos"
            })
    
            setFooterLogos(formatLogoData(logoReponse.items[0].fields.footerLogo));
    
            setLoading(false);
    
        }
        catch(error){
            console.log(error)
        }
    }



    useEffect(()=>{
        getData();
    },[])

const formatData = (items) =>{
    let tempItems = items.map(item => {
        let id = item.sys.id
        let addressArray ={...item.fields, id};

        return addressArray;
    })
    return tempItems;
}

const formatLogoData = (items) =>{

    let tempItems = items.map(item =>{
    let logoArray = {...item.fields.file}

        return logoArray;

    })

    return tempItems;

}

    return(

    <WorkAddressContext.Provider value={{loading, workAddress, footerLogos}}>
        {props.children}
    </WorkAddressContext.Provider>

    )

}


//Projects

export const ProjectsContext = createContext();

export const ProjectProvider = (props) =>{

    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState(0);
    const [sortedProjects, setSortedProjects] = useState(0);

        
    const [workType, setWorkType] = useState([])
    const [serviceType, setServiceType] = useState([])
    const [projectType, setProjectType] = useState([])


    //getData
    const getData = async()=>{
        try{
            let response = await Client.getEntries({
                content_type: "project",
                order: '-sys.createdAt'
    
            });
                
            setProjects(formatData(response.items));
            setSortedProjects(formatData(response.items));
    
            setLoading(false);
    
        }
        catch(error){
            console.log(error)
        }
    }


useEffect(()=>{
    getData();
},[])



const getProject = (slug) =>{

    if(!loading){
        let tempProjects = [...projects]
        const project = tempProjects.find(project => project.slug === slug);
        return project;
    }


}


const formatData = (items) =>{
    let tempItems = items.map(item => {
        let id = item.sys.id;
        let thumbnail = item.fields.thumbnail.fields.file.url;
        let addressArray ={...item.fields, id, thumbnail};

        return addressArray;
    })
    return tempItems;
}

const serviceHandleChange = (page)=>{


    setWorkType([])
    setServiceType([])
    setProjectType([])

    setServiceType(page)
}


const handleChange = (workType, serviceType, projectType) =>{

    setWorkType([]);
    setServiceType([]);
    setProjectType([]);

    setWorkType(workType);
    setServiceType(serviceType);
    setProjectType(projectType);


}


const filterProjects =()=>{

    let tempProjects = [...projects]

    //filter by work type
        if(workType.length !== 0){
            tempProjects = tempProjects.filter(project => workType.includes(project.workType));
        }
    
        if(serviceType.length !== 0){

            tempProjects = tempProjects.filter(project => serviceType.includes(project.service));
        }

        if(projectType.length !== 0){
            tempProjects = tempProjects.filter(project =>  projectType.includes(project.projectType)); 
        }
    
     setSortedProjects(tempProjects);

}



    useEffect(() => {
        if(!loading){

            if(workType && serviceType && projectType){

                filterProjects();
            }
        }
    
    }, [workType, serviceType, projectType, loading, projects])


    return(

    <ProjectsContext.Provider 
        value={{loading, projects, sortedProjects, 
                getProject: getProject, 
                handleChange: handleChange, 
                serviceHandleChange: serviceHandleChange
                }}>
        {props.children}
    </ProjectsContext.Provider>

    )

}