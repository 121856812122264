  export const menuLinks = [
    { id: 0, title: "Home", path: "/" },
    { id: 1, title: "About", path: "/about" },
    { id: 2, title: "Projects", path: "/projects" },
    { id: 3, title: "Services", path: "/services" },
    { id: 4, title: "Contact", path: "/contact" }
  ];


  
  
  